// hooks.ts
import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';
import type { RootState, AppDispatch } from './store';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

export function gtag_report_conversion(url?: string): boolean {
  const callback = () => {
    if (url) {
      window.location.href = url;
    }
  };

  window.gtag("event", "conversion", {
    send_to: "AW-746675905/OtunCLXo9YAaEMG9heQC",
    event_callback: callback,
  });

  return false; // Prevent default behavior if needed
}
