import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Login from "./components/login/Login";
import ResendOtp from "./components/login/ResendOtp";
import Gallery from "./components/gallery/Gallery";
import Checkout from "./components/checkout/Checkout";
import MainCustomer from "./components/customer profile/MainCustomer";
import Footer from "./components/footer/Footer";
import TripDetails from "./components/tripDetails/TripDetails";
import Home from "./components/home/Home";
import Aboutus from "./components/about/Aboutus";
import Backpacking from "./components/backpacking/Backpacking";
import BlogPage from "./components/blogs/blogpage/BlogPage";
import BlogDetails from "./components/blogs/blogdetails/BlogDetails";
import ContactUs from "./components/contact/contactus/ContactUs";
import GeneralDetails from "./components/details/generaldetails/GeneralDetails";
import WeekendTrips from "./components/weekendtrips/WeekendTrips";
import Signup from "./components/signup/Signup";
import Personalized from "./components/personalized/Personalized";
import Header from "./components/header/Header";
import Featured from "./components/featured/Featured";
import International from "./components/international/International";
import PDFViewerPage from "./components/pdf/pdfViewer";
import Paymentsuccess from "./components/payment/Paymentsuccess";
import Paymentfailed from "./components/payment/Paymentfailed";
import Travellar from "./components/traveller/Traveller";
import Booking from "./components/customer profile/Booking";
import Voucher from "./components/customer profile/Voucher";
import WishList from "./components/customer profile/Wishlist";
import CustomerProfile from "./components/customer profile/CustomerProfile";
import Career from "./components/career/Career";
import Disclaimer from "./components/disclaimer/Disclaimer";
import PrivacyPolicy from "./components/Privacy Policy/Privacypolicy";
import CancellationPolicy from "./components/Cancellation Policy/CancellationPolicy";
import TermsConditions from "./components/Terms Conditions/TermsConditions";
import PayAfter from "./components/afterpay/Afterpay";
import AdventurePage from "./components/Adventure Page/AdventurePage";
import { app, analytics } from "./firebase"; // Adjust the path if needed

function App() {
  
  const isAuthenticated = useSelector(
    (state: any) => state.auth.isAuthenticated
  );
  const location = useLocation();

  useEffect(() => {
    const scrollDelay = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => clearTimeout(scrollDelay);
  }, [location.pathname]);

  const showHeader = () => {
    const pathsWithoutHeader = ["/checkout"];
    return !pathsWithoutHeader.some((path) => location.pathname.includes(path));
  };

  return (
    <>
      {showHeader() && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resend" element={<ResendOtp />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/paymentsuccess" element={<Paymentsuccess />} />
        <Route path="/paymentfailed" element={<Paymentfailed />} />
        <Route
          path="/maincustomer/*"
          element={isAuthenticated ? <MainCustomer /> : <Navigate to="/" />}
        >
          <Route path="profile" element={<CustomerProfile />} />
          <Route path="wishlist" element={<WishList />} />
          <Route path="bookings" element={<Booking />} />
          <Route path="vouchers" element={<Voucher />} />
        </Route>
        <Route path="profile" element={<CustomerProfile />} />
        <Route path="career" element={<Career />} />
        <Route path="Disclaimer" element={<Disclaimer />} />
        <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="CancellationPolicy" element={<CancellationPolicy />} />
        <Route path="TermsConditions" element={<TermsConditions />} />
        <Route path="wishlist" element={<WishList />} />
        <Route path="vouchers" element={<Voucher />} />
        <Route path="booking" element={<Booking />} />
        <Route path="/tripdetails/:id" element={<TripDetails />} />
        <Route path="/aboutus" element={<Aboutus />} />
        <Route path="/backpackingtrips" element={<Backpacking />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blogdetails/:id" element={<BlogDetails />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/travller" element={<Travellar />} />
        <Route path="/generaldetails" element={<GeneralDetails />} />
        <Route path="/weekendtrips" element={<WeekendTrips />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/personalized" element={<Personalized />} />
        <Route path="/featured-package/:id" element={<Featured sourced="featuredTour" />} />
        <Route path="/featured/:id" element={<Featured sourced="menu" />} />
        <Route path="/international-departure" element={<International />} />
        <Route path="/afterpay/:link_id" element={<PayAfter />} />
        <Route path="/pdfviewer/:id" element={<PDFViewerPage />} />
        <Route path="/adventure-page" element={<AdventurePage />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
