import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import "./Addon.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { Loader } from "../../loader/Loader";

interface AddOnData {
  title: string;
  status: string;
  service: string;
  price: number;
  quantity: number;
  totalAmount: number;
  firstname: string;
}

interface AddOnProps {
  applyTourId: any ;
  onTotalAmountChange: (totalAmount: number) => void;
}
const AddOn: React.FC<AddOnProps> = ({ onTotalAmountChange, applyTourId }) => {
  const [data, setData] = useState<AddOnData[]>([]);
  const [load, setLoad] = useState<boolean>(true);
  const [userName, setUserName] = useState({
    firstname: "",
  });
  const location = useLocation();
  const { id } = location.state || {};
  const addData = async (_id: string) => {
    setLoad(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/get-all-add-addon-notes?applyTour_id=${applyTourId}`
      );
      if (response.data.success) {
        const addonsData = response.data.data[0].addons_data;
        setData(addonsData);
        setUserName(response.data);

        // Calculate total amount
        const totalAmount = addonsData.reduce(
          (sum: number, item: AddOnData) => sum + item.price * item.quantity,
          0
        );
        onTotalAmountChange(totalAmount);
      } else {
        console.log(response.data, "AddOn error");
      }
    } catch (error) {
      console.log("Error fetching data:", error);
    }
    setLoad(false);
  };

  useEffect(() => {
    if (applyTourId) {
      addData(applyTourId);
    }
  }, [applyTourId]);

  return (
    <>
      {data.length === 0 ? (
        <p className="text-dark p-2">No data found</p>
      ) : (
        <div className="container">
          {data.map((item: any, index: number) => (
            <Form key={index}>
              <Form.Group className="mb-3 customer-border lable pt-4 pb-2">
                <span className="profilekrish text-dark">
                  {userName.firstname}
                </span>
              </Form.Group>
              <div className="row">
                <div className="addonrow">
                  <div className="col-9 addonrow2">
                    <div className="col-3">
                      <p className="addonpara">Add-on {index + 1}</p>
                      <p className="stspara">
                        Status :
                        <span className="paidpara"> {item.status} </span>
                      </p>
                    </div>
                    <div className="col-3 stsadd">
                      <p className="srvicpara">Service</p>
                      <p className="srvicpara2">{item.service}</p>
                    </div>
                    <div className="col-3">
                      <p className="srvicpara">Price</p>
                      <p className="srvicpara2">₹{item.price}</p>
                    </div>
                    <div className="col-3">
                      <p className="srvicpara">Quantity</p>
                      <p className="srvicpara2">{item.quantity}</p>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="totalon">
                      <p className="totalpara">Total Amount</p>
                      <p className="totalpara2">
                        ₹ {item.price * item.quantity}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          ))}
        </div>
      )}
    </>
  );
};

export default AddOn;
