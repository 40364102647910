import "../checkout/Checkout.css";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Loader } from "../loader/Loader";
import Swal from "sweetalert2";
import { getTraveller } from "../../Reducers/authSlice";
import { useAppDispatch, useAppSelector } from "../../hook";
import { ReactNode } from "react";
const Checkout = () => {
  interface coup {
    coupon_code: string;
    coupon_amount: number;
    coupon_description: string;
    coupon_expiry_date: any;
  }
  interface AppliedCoupon {
    coupon_amount: ReactNode;
    code: string;
    discountedPrice: number;
  }
  const dispatch = useAppDispatch();

  const storedId = localStorage.getItem("id");

  const location = useLocation();

  const id = location?.state || {};
  // console.log(id, location, "hello");
  const [load, setLoad] = useState<boolean>(true);

  const checkoutId = id;
  const [checkoutData, setCheckoutData] = useState<any>(null);
  const [panNumber, setPanNumber] = useState("");
  const [coupons, setCoupons] = useState<coup[]>([]);
  const [appliedCoupons, setAppliedCoupons] = useState<AppliedCoupon[]>([]);
  const [newCoupons, setNewCoupons] = useState<any>("");
  const [showModal, setShowModal] = useState(false);
  const [domestincorInternational, setDomesticorInternational] =
    useState<any>(null);
  const [state, setState] = useState<any>(null);
  const [gst, setGst] = useState<any>(null);
  const [tcs, setTcs] = useState<any>(null);
  const [idData, setIdData] = useState(null);
  const [paymentType, setPaymentType] = useState<any>("Complete");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [otherChareges, setOtherCharges] = useState<any>(null);
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [couponCode, setCouponCode] = useState<any>("");
  const [coupondata, setCouponData] = useState<any>();
  const [afterCouponPrice, setAfterCouponPrice] = useState<number>(0);
  const [discountedPrice, setDiscountedPrice] = useState<number>(0);
  const [tourPrice, setTourPrice] = useState<number>(0);
  const formatTravelDate = (dateRange: any) => {
    if (!dateRange) return "";

    // Split the date range string
    const [startDate, endDate] = dateRange.split("-");

    // Function to format individual date
    const formatDate = (dateString: any) => {
      const [day, month, year] = dateString.split("/");
      // Parse to Date object
      const date = new Date(`${year}-${month}-${day}`);
      return date.toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "short",
        year: "numeric",
      });
    };

    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };
  const navigate = useNavigate();
  let travellerCounts = checkoutData?.getData?.travel_no_of_traveler;
  let value = travellerCounts?.toString();
  setTimeout(() => {
    localStorage.setItem("TravellerCounts", value);
  }, 50);
  useEffect(() => {
    loadCheckoutData();
    AllotherCharges();
  }, []);
  const loadCheckoutData = async () => {
    try {
      setLoad(true);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/get-appliedTourById`,
        {
          id: id,
        }
      );

      const tourId = response?.data?.tourData?._id;

      if (response?.data?.success) {
        setIdData(tourId);
        setCheckoutData(response?.data);
        setAfterCouponPrice(response?.data?.getData?.finalPrice);

        const travellerDetails = {
          value: response?.data?.getData,
        };
        dispatch(getTraveller(travellerDetails));

        setDomesticorInternational(
          response?.data?.getData?.domesticorInternational
        );
        setState(response?.data?.getData.user_id.state);
        setGst(response?.data.applyTourbatchId[0].gst);
        setTcs(response?.data.applyTourbatchId[0].tcs);
        loadAllCoupons(tourId); // Load all coupons for the given tourId
      }
    } catch (error) {
      console.error("Error loading checkout data", error);
    } finally {
      setLoad(false);
    }
  };
  const handleChange = (e: any) => {
    setPanNumber(e.target.value);
  };

  const validatePanCard = () => {
    if (!panNumber) {
      Swal.fire({
        icon: "error",
        title: "Invalid PAN Number",
        text: "Please enter a valid 10-digit PAN number (e.g., ABCDE1234F)",
      });
    }
  };

  const loadAllCoupons = async (data: any) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/admin/get-all-active-coupon-by-tour-id/${data}`
    );
    if (response?.data?.success) {
      setCoupons(response.data.data);
      setCouponData(response.data.data);
    }
  };
  const handleInputChange = (event: any) => {
    setCouponCode(event.target.value);
  };
  const NewCoupons = async (code: any) => {
    setLoad(true);
    setError(null);
    setCouponCode(code);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/applyCouponForBookTour`,
        {
          couponCode: code || couponCode,
          tourId: idData,
          id: checkoutId,
          tour_price: Number(checkoutData?.getData?.pricetourWithoutTaxes),
          user_id: checkoutData?.getData?.user_id?._id,
        }
      );

      if (response?.data?.success) {
        const appliedCoupon = response.data.getData.appliedCoupon;
        setAppliedCoupons([appliedCoupon]);

        // Wait for 2 seconds after successful coupon application
        setTimeout(() => {
          loadCheckoutData(); // Call loadCheckoutData after 2 seconds
        }, 2000); // Updated to 2 seconds
      } else {
        // Check if the response contains a specific message
        const errorMessage = response?.data?.message;

        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: errorMessage,
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while applying the coupon by ID.",
      });
    } finally {
      setLoad(false);
    }
  };

  let OrderNote = checkoutData?.getData.tour_id.orderNote;

  const calculateDiscountedPrice = (
    totalFinalPrice: number,
    totalCouponAmount: number
  ): number => {
    return totalFinalPrice - totalCouponAmount;
  };

  const calculatePercentageDiscount = (
    finalPrice: number,
    percentage: number
  ): number => {
    return (finalPrice * percentage) / 100;
  };

  const isPercentageDiscount = (couponAmount: number): boolean => {
    return couponAmount > 0 && couponAmount <= 100;
  };
  useEffect(() => {
    if (checkoutData?.getData) {
      const price = checkoutData.getData.TourPrice || 0; // Get the TourPrice dynamically
      setTourPrice(price);
      setDiscountedPrice(price); // Initialize discountedPrice with the TourPrice
    }
  }, [checkoutData]);
  const TourPrice = checkoutData?.getData?.TourPrice || 0;
  const finalPrice = checkoutData?.getData?.finalPrice || 0;
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  function formatTotalAmount(amount: any) {
    amount = parseFloat(amount);

    if (isNaN(amount)) {
      return "0";
    }

    return amount.toLocaleString("en-IN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  const checkoutResponse = localStorage.getItem("checkoutResponse");
  let objId = "";
  if (checkoutResponse) {
    const parsedCheckoutResponse = JSON.parse(checkoutResponse);
    objId = parsedCheckoutResponse.objId;
  }

  const boardingPrice = (city: string, type: string) => {
    if (checkoutData?.applyTourbatchId?.length) {
      let tourData = checkoutData?.applyTourbatchId[0];
      let boardingArr = JSON.parse(tourData?.boarding_point_price);
      let boardingCityObj = boardingArr.find((o: any) => o.bp === city);
      let boardingPriceIndex = boardingCityObj?.bp_price.findIndex(
        (o: any) => Object.keys(o)[0] == type
      );
      return boardingCityObj?.bp_price?.[boardingPriceIndex]?.[type];
    } else {
      return 0;
    }
  };

  let SGSTamount = checkoutData?.getData?.amtWithSGSTNewCharges;
  let CGSTamount = checkoutData?.getData?.amtWithCGSTNewCharges;
  let IGSTamount = checkoutData?.getData?.amtWithIGSTNewCharges;
  let TCSamount = checkoutData?.getData?.amtWithTCSNewCharges;

  const totalAmount = () => {
    let totalPackageAmount =
      checkoutData?.getData?.travel_packageType_data?.reduce(
        (sum: number, item: any) => sum + item.persons * item.packPrice,
        0
      );

    let totalRoomAmount =
      checkoutData?.getData?.travel_roomSharing_data?.reduce(
        (sum: number, item: any) => sum + item.persons * item.roomPrice,
        0
      );
    let totalKidsAmount =
      checkoutData?.getData?.child_price * checkoutData?.getData?.child_count;
    let totalInfantAmount =
      checkoutData?.getData?.infant_count * checkoutData?.getData.infant_price;
    return (
      totalPackageAmount +
        totalRoomAmount +
        totalKidsAmount +
        totalInfantAmount +
        Number(
          boardingPrice(
            checkoutData?.getData?.travel_boarding_point?.boarding,
            checkoutData?.getData?.boardingType
          )
        ) *
          checkoutData?.getData?.old_travel_no_of_traveler || 0
    );
  };
  const responseValue = checkoutData?.getData?.old_travel_no_of_traveler;
  const makePayment = async () => {
    if (domestincorInternational === "international" && !panNumber) {
      Swal.fire({
        icon: "error",
        title: "PAN Number Required",
        text: "Please enter your PAN Number.",
      });
      return;
    }
    if (termsAgreed) {
      try {
        let totalAmountToSend = totalAmount();
        let amountPaid = totalAmount();

        if (appliedCoupons.length > 0) {
          const couponAmount = Number(appliedCoupons[0]?.coupon_amount) || 0;
          const price = Number(finalPrice) || 0;

          totalAmountToSend = calculateDiscountedPrice(price, couponAmount);
          amountPaid = calculateDiscountedPrice(price, couponAmount);
        }

        if (paymentType === "Partial") {
          // amountPaid = checkoutData?.applyTourbatchId[0]?.bookPrice;
          amountPaid =
            checkoutData?.applyTourbatchId[0]?.bookPrice * responseValue;
        }

        let paymentObj = {
          amount_paid: amountPaid,
          appliedCoupon: appliedCoupons[0]?.coupon_amount || 0,
          bookingPrice: checkoutData?.applyTourbatchId[0]?.bookPrice,
          cgst: otherChareges?.[0]?.cgst,
          checkout_id: checkoutId,
          id: objId,
          cusGSTNo: "",
          cuscompanyAddress: "",
          cuscompanyName: "",
          igst: otherChareges?.[0]?.igst,
          isOuter: true,
          // partial_payment: checkoutData?.applyTourbatchId[0]?.bookPrice,
          partial_payment:
            checkoutData?.applyTourbatchId[0]?.bookPrice * responseValue,
          payment_status: paymentType === "Partial" ? "Partial" : "Complete",
          sgst: otherChareges?.[0]?.sgst,
          total_amount: totalAmountToSend,
          user_coupon: appliedCoupons.length > 0 ? appliedCoupons[0].code : "",
          useremail: checkoutData?.getData?.user_id?.email,
          username:
            checkoutData?.getData?.user_id?.firstname +
            " " +
            checkoutData?.getData?.user_id?.lastname,
          user_id: checkoutData?.getData?.user_id?._id,
          panNumber: panNumber,
          bookedBy: checkoutData?.getData?.user_id?._id,
          user_address: `${checkoutData?.getData?.user_id?.city}, ${checkoutData?.getData?.user_id?.state}`,
        };
        if (paymentType === "Partial") {
          paymentObj.amount_paid =
            checkoutData?.applyTourbatchId[0]?.bookPrice * responseValue;
          paymentObj.payment_status = "Partial";
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/ccavRequestHandler`,
          paymentObj
        );

        if (response.data.success) {
          window.location.href = response.data.encryptedRequest;
        } else {
          console.log("error");
        }
      } catch (error) {
        console.error("Error making payment:", error);
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Terms and Conditions",
        text: "Please accept the terms and conditions.",
      });
    }
  };

  const applyCoupon = (coupon: any) => {
    const discount = isPercentageDiscount(coupon.coupon_amount)
      ? calculatePercentageDiscount(tourPrice, coupon.coupon_amount)
      : coupondata?.[0].coupon_amount *
        (checkoutData?.getData?.old_travel_no_of_traveler || 1);

    const newDiscountedPrice = calculateDiscountedPrice(tourPrice, discount);
    setDiscountedPrice(newDiscountedPrice); // Update the discounted price
    setAppliedCoupons((prev) => [...prev, coupon]); // Store applied coupon
    // Additional logic for applying the coupon
  };
  const calculateFinalAmount = () => {
    return appliedCoupons.length > 0 ? discountedPrice : tourPrice;
  };

  const AllotherCharges = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/admin/get-all-other-charge`)
      .then((res) => {
        if (res.data.success) {
          setOtherCharges(res.data.data);
        } else {
          console.log("something went wrong");
        }
      })
      .catch((err) => console.log(err, "error in catch"));
  };

  const code_applying = (code: any) => {
    NewCoupons(code);
  };

  const removeCoupon = async (code: any) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/applyCouponForBookTour`,
        {
          couponCode: code || couponCode, // Pass the coupon code or the current couponCode state
          tourId: idData,
          id: checkoutId,
          removeCoupon: "Remove",
          tour_price: Number(checkoutData?.getData?.TourPrice),
          user_id: checkoutData?.getData?.user_id?._id,
        }
      );

      if (response?.data?.success) {
        setAppliedCoupons([]); // Clear applied coupons if successful
        Swal.fire({
          icon: "success",
          title: "Coupon Removed",
          text: "The coupon has been successfully removed.",
        });

        // Call loadCheckoutData after 1 second
        setTimeout(() => {
          loadCheckoutData();
        }, 1000);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to remove coupon.",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "An error occurred while removing the coupon.",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (event: any) => {
    setTermsAgreed(event.target.checked);
  };
  const handleApplyButtonClick = () => {
    if (couponCode) {
      NewCoupons(couponCode);
      // setCouponCode("");
    } else {
      // setError('Please enter a coupon code.')
    }
  };

  return (
    <>
      {load ? <Loader /> : ""}

      <div className="container fnt">
        <div className="m-5 main_bx  p-2">
          <div className="header_pr  d-flex justify-content-center align-items-center text-danger ">
            <h1 className="pl-4 checkoutpage text_pink">Checkout</h1>
          </div>
          <div className="userdetail_pr p-3  d-flex justify-content-between align-items-center flex-wrap">
            <div className="respn detail_bx">
              <div className="d-flex justify-content-start  align-items-center">
                <p className="left_txt">Name</p>
                <p className="semicolon ">:</p>
                <p className="right_txt d-flex">
                  {checkoutData?.getData?.user_id?.firstname}{" "}
                  {checkoutData?.getData?.user_id?.lastname}
                </p>
              </div>
              <div className="d-flex justify-content-start  align-items-center">
                <p className="left_txt">Mob. No.</p>
                <p className="semicolon">:</p>
                <p className="right_txt">
                  {checkoutData?.getData?.user_id?.mobile}
                </p>
              </div>
            </div>
            <div className=" respn detail_bx">
              <div className="d-flex justify-content-start align-items-center">
                <p className="left_txt">Tour Name</p>
                <p className="semicolon">:</p>
                <p className="right_txt">
                  {checkoutData?.getData?.tour_id?.title}
                </p>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <p className="left_txt">Date</p>
                <p className="semicolon">:</p>
                <p className="right_txt">
                  {formatTravelDate(checkoutData?.getData?.travel_date)}
                </p>
              </div>
            </div>
            <div className=" respn detail_bx">
              <div className="d-flex justify-content-start align-items-center">
                <p className="left_txt">Email</p>
                <p className="semicolon">:</p>
                <p className="right_txt">
                  {checkoutData?.getData?.user_id?.email}
                </p>
              </div>
              <div className="d-flex justify-content-start align-items-center">
                <p className="left_txt">No. of Traveller</p>
                <p className="semicolon">:</p>
                <p className="right_txt">
                  {checkoutData?.getData?.travel_no_of_traveler}
                </p>
              </div>
            </div>
          </div>
          <hr />
          <div className=" packagedetail_pr d-flex flex-wrap justify-content-start align-items-start">
            <div className="respn left_pkg  p-3  ">
              <div className="pkg_type">
                <div className="txt_head">Package Type</div>
                <div className="bottom_detail_bx">
                  {checkoutData?.getData &&
                    checkoutData?.getData?.travel_packageType_data.length &&
                    checkoutData?.getData?.travel_packageType_data.map(
                      (o: any, index: number) =>
                        o.packPrice > 0 &&
                        o.persons > 0 && (
                          <div
                            className="d-flex justify-content-between align-items-center"
                            key={index}
                          >
                            <p className="pkg_left_txt">{o.packType}</p>
                            <p className="semicolon">:</p>
                            <p className="respn2 pkg_right_txt d-flex">
                              ₹{formatTotalAmount(o.packPrice)} / person X{" "}
                              {o.persons} = ₹
                              {formatTotalAmount(o.packPrice * o.persons)}
                            </p>
                          </div>
                        )
                    )}
                </div>

                {checkoutData?.getData?.child_price > 0 &&
                  checkoutData?.getData?.child_count > 0 && (
                    <div className="txt_head">Child</div>
                  )}

                {checkoutData?.getData?.child_price > 0 &&
                  checkoutData?.getData?.child_count > 0 && (
                    <div className="bottom_detail_bx">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="pkg_left_txt">
                          Kids (Age {checkoutData?.getData?.tour_id?.childfrom}-
                          {checkoutData?.getData?.tour_id?.childto})
                        </p>
                        <p className="semicolon">:</p>
                        <p className="pkg_right_txt d-flex">
                          ₹
                          {formatTotalAmount(
                            checkoutData?.getData?.child_price
                          )}{" "}
                          / person X {checkoutData?.getData?.child_count} = ₹
                          {formatTotalAmount(
                            checkoutData?.getData?.child_price *
                              checkoutData?.getData?.child_count
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                {checkoutData?.getData?.infant_price > 0 &&
                  checkoutData?.getData?.infant_count > 0 && (
                    <div className="bottom_detail_bx">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="pkg_left_txt">
                          Infant ({checkoutData?.getData?.tour_id?.infantfrom}-
                          {checkoutData?.getData?.tour_id?.infantto})
                        </p>
                        <p className="semicolon">:</p>
                        <p className="pkg_right_txt d-flex">
                          ₹
                          {formatTotalAmount(
                            checkoutData?.getData?.infant_price
                          )}
                          / person X {checkoutData?.getData?.infant_count} = ₹
                          {formatTotalAmount(
                            checkoutData?.getData?.infant_price *
                              checkoutData?.getData?.infant_count
                          )}
                        </p>
                      </div>
                    </div>
                  )}
              </div>

              {checkoutData?.getData?.travel_roomSharing_data &&
                checkoutData?.getData?.travel_roomSharing_data.length > 0 &&
                checkoutData?.getData?.travel_roomSharing_data.some(
                  (o: any) => o.roomPrice > 0 && o.persons > 0
                ) && (
                  <>
                    <hr />
                    <div className="txt_head">Room Sharing</div>
                  </>
                )}

              {checkoutData?.getData &&
                checkoutData?.getData?.travel_roomSharing_data.length > 0 &&
                checkoutData?.getData?.travel_roomSharing_data.map(
                  (o: any, index: number) =>
                    o.roomPrice > 0 &&
                    o.persons > 0 && (
                      <div className="bottom_detail_bx" key={index}>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="pkg_left_txt">{o.roomType}</p>
                          <p className="semicolon">:</p>
                          <p className="pkg_right_txt d-flex">
                            ₹{formatTotalAmount(o.roomPrice)} / person X{" "}
                            {o.persons} = ₹
                            {formatTotalAmount(o.roomPrice * o.persons)}
                          </p>
                        </div>
                      </div>
                    )
                )}
              {checkoutData?.getData?.travel_boarding_point?.boarding &&
              boardingPrice(
                checkoutData?.getData?.travel_boarding_point?.boarding,
                checkoutData?.getData?.boardingType
              ) > 0 ? (
                <hr />
              ) : null}

              {checkoutData?.getData?.travel_boarding_point?.boarding &&
                checkoutData?.getData?.old_travel_no_of_traveler > 0 && (
                  <div>
                    <div className="txt_head">Boarding Point</div>
                    <div className="bottom_detail_bx">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="pkg_left_txt">
                          {
                            checkoutData?.getData?.travel_boarding_point
                              ?.boarding
                          }
                        </p>
                        <p className="semicolon">:</p>
                        <p className="pkg_right_txt d-flex">
                          ₹
                          {formatTotalAmount(
                            isNaN(
                              boardingPrice(
                                checkoutData?.getData?.travel_boarding_point
                                  ?.boarding,
                                checkoutData?.getData?.boardingType
                              )
                            )
                              ? 0
                              : boardingPrice(
                                  checkoutData?.getData?.travel_boarding_point
                                    ?.boarding,
                                  checkoutData?.getData?.boardingType
                                )
                          )}{" "}
                          / person X{" "}
                          {checkoutData?.getData?.old_travel_no_of_traveler} = ₹
                          {formatTotalAmount(
                            isNaN(
                              boardingPrice(
                                checkoutData?.getData?.travel_boarding_point
                                  ?.boarding,
                                checkoutData?.getData?.boardingType
                              ) *
                                checkoutData?.getData?.old_travel_no_of_traveler
                            )
                              ? 0
                              : boardingPrice(
                                  checkoutData?.getData?.travel_boarding_point
                                    ?.boarding,
                                  checkoutData?.getData?.boardingType
                                ) *
                                  checkoutData?.getData
                                    ?.old_travel_no_of_traveler
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                )}

              <hr />
              <div className=" bottom_detail_bx">
                <div className="d-flex justify-content-between  align-items-center ">
                  <p className="w-50 amount ">Total Amount</p>
                  <p className="amount d-flex">₹{TourPrice}</p>
                </div>
              </div>
              <div>
                <div id="coupon_applied">
                  {appliedCoupons.map((appliedCoupon: any) => (
                    <div
                      className="coupon_box border border-success w-100 rounded m-2 d-flex justify-content-between align-items-center"
                      key={appliedCoupon.code}
                    >
                      <div className="d-flex">
                        <i
                          className="fa-regular fa-xl fa-circle-check my-2 p-3"
                          style={{ color: "#0fef0b" }}
                        ></i>
                        <div>
                          <div className="d-flex m-1">
                            <p className="fw-semibold mx-1">{couponCode}</p>
                            <p>applied</p>
                          </div>
                          <div className="coupon_diff">
                            ₹{TourPrice} - ₹
                            {(() => {
                              const computedDiscount = isPercentageDiscount(
                                appliedCoupon.coupon_amount
                              )
                                ? calculatePercentageDiscount(
                                    TourPrice,
                                    appliedCoupon.coupon_amount
                                  )
                                : coupondata?.[0].coupon_amount *
                                  (checkoutData?.getData
                                    ?.old_travel_no_of_traveler || 1);
                              return computedDiscount;
                            })()}{" "}
                            = ₹
                            {(() => {
                              const discountedTotal = calculateDiscountedPrice(
                                TourPrice,
                                isPercentageDiscount(
                                  appliedCoupon.coupon_amount
                                )
                                  ? calculatePercentageDiscount(
                                      TourPrice,
                                      appliedCoupon.coupon_amount
                                    )
                                  : coupondata?.[0].coupon_amount *
                                      (checkoutData?.getData
                                        ?.old_travel_no_of_traveler || 1)
                              );
                              return discountedTotal;
                            })()}
                          </div>
                        </div>
                      </div>
                      <button
                        className="btn fw-semibold"
                        onClick={() => removeCoupon(appliedCoupon.code)}
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
                {appliedCoupons.length > 0 && (
                  <div className="bottom_detail_bx">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="w-50 amount">Total Amount</p>
                      <p className="amount d-flex">
                        ₹
                        {(() => {
                          const firstAppliedCoupon = appliedCoupons[0]; // Get the first applied coupon

                          // Ensure that coupon_amount is a number or defaults to 0
                          const couponAmount: any =
                            firstAppliedCoupon?.coupon_amount ?? 0;

                          const computedDiscount = isPercentageDiscount(
                            couponAmount
                          )
                            ? calculatePercentageDiscount(
                                TourPrice,
                                couponAmount
                              )
                            : (coupondata?.[0].coupon_amount ?? 0) *
                              (checkoutData?.getData
                                ?.old_travel_no_of_traveler ?? 1);

                          const discountedTotal = calculateDiscountedPrice(
                            TourPrice,
                            computedDiscount
                          );
                          return discountedTotal ?? 0; // Ensure discountedTotal is a number
                        })()}
                      </p>
                    </div>
                  </div>
                )}

                <div className="coupon_bx d-flex justify-content-center align-items-center m-4">
                  <button
                    type="button"
                    className="btn bgpink couponbtn"
                    data-bs-toggle="modal"
                    data-bs-target="#staticBackdrop"
                  >
                    View Coupons
                  </button>
                  <div
                    className="modal fade"
                    id="staticBackdrop"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabIndex={-1}
                    aria-labelledby="staticBackdropLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content brder_popup">
                        <div className="modal-header">
                          <h5 className="modal-title" id="staticBackdropLabel">
                            Coupon Codes
                          </h5>
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          ></button>
                        </div>
                        <div className="modal-body">
                          {coupons.length > 0 ? (
                            coupons.map((o) => (
                              <div
                                className="justify-content-between align-items-center"
                                key={o.coupon_code}
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <div className="d-flex justify-content-center align-items-center mx-2">
                                    <i
                                      className="fa-solid fa-tags"
                                      style={{ color: "#32b849" }}
                                    ></i>
                                    <p className="mx-2 fw-semibold" id="C8">
                                      {o.coupon_code}
                                    </p>
                                  </div>
                                  <button
                                    data-bs-dismiss="modal"
                                    className="btn text_pink fw-semibold"
                                    onClick={() => code_applying(o.coupon_code)}
                                  >
                                    Apply
                                  </button>
                                </div>
                                <p className="desri_sty">
                                  {o.coupon_description}
                                </p>
                                <p className="expdat">
                                  Exp -{" "}
                                  {new Intl.DateTimeFormat("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  }).format(new Date(o.coupon_expiry_date))}
                                </p>
                              </div>
                            ))
                          ) : (
                            <p>No coupons available</p>
                          )}

                          <div className="modal-footer d-flex justify-content-between align-items-center m-1">
                            <input
                              className="form-control w-50 border border-danger"
                              type="text"
                              placeholder="Coupon Code"
                              value={couponCode}
                              onChange={handleInputChange}
                            />
                            <button
                              type="button"
                              className="btn code_applybtn fw-semibold"
                              onClick={handleApplyButtonClick}
                              data-bs-dismiss="modal"
                            >
                              Apply
                            </button>
                          </div>
                          {error && <div style={{ color: "red" }}>{error}</div>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" respn right_pkg p-3 ">
              <div className="pkg_type">
                <div className="txt_head">Summary</div>

                <div className="bottom_detail_bx">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">Sub Total</p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹
                      {Number(
                        checkoutData?.getData?.pricetourWithoutTaxes
                      ).toLocaleString("en-IN", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
              </div>
              <hr />
              <div className="bottom_detail_bx">
                {Number(SGSTamount) > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">
                      SGST({otherChareges?.[0]?.sgst}%)
                    </p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹{Number(SGSTamount).toFixed(2)}
                    </p>
                  </div>
                )}

                {Number(CGSTamount) > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">
                      CGST({otherChareges?.[0]?.cgst}%)
                    </p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹{Number(CGSTamount).toFixed(2)}
                    </p>
                  </div>
                )}

                {Number(IGSTamount) > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">
                      IGST({otherChareges?.[0]?.igst}%)
                    </p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹{Number(IGSTamount).toFixed(2)}
                    </p>
                  </div>
                )}

                {Number(TCSamount) > 0 && (
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="pkg_left_txt">
                      TCS({otherChareges?.[0]?.tcs}%)
                    </p>
                    <p className="semicolon">:</p>
                    <p className="pkg_right_txt d-flex">
                      ₹{Number(TCSamount).toFixed(2)}
                    </p>
                  </div>
                )}
              </div>

              <hr />
              <div className="d-flex justify-content-between  align-items-center">
                <div className="form-check fw-semibold">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="Complete"
                    checked={paymentType === "Complete"}
                    onChange={(e: any) => setPaymentType(e.target.value)}
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                  />
                  <label className="form-check-label ">Pay Complete</label>
                </div>
                <div className="form-check fw-semibold">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="Partial"
                    checked={paymentType === "Partial"}
                    onChange={(e: any) => setPaymentType(e.target.value)}
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label className="form-check-label">Pay Partial</label>
                </div>
              </div>
              <div className="bottom_detail_bx">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="w-50">Price</p>
                  <p className="d-flex">
                    ₹ {finalPrice}
                    {/* {appliedCoupons.length > 0
                      ? formatTotalAmount(
                          calculateDiscountedPrice(
                            Number(finalPrice) || 0,
                            isPercentageDiscount(
                              Number(appliedCoupons[0]?.coupon_amount)
                            )
                              ? calculatePercentageDiscount(
                                  Number(finalPrice) || 0,
                                  Number(appliedCoupons[0]?.coupon_amount)
                                )
                              : Number(appliedCoupons[0]?.coupon_amount) *
                                  (checkoutData?.getData
                                    ?.old_travel_no_of_traveler || 1)
                          )
                        )
                      : formatTotalAmount(Number(afterCouponPrice) || 0)} */}
                  </p>
                </div>
              </div>

              <hr />
              <div className="bottom_detail_bx">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="w-50 amountf">Amount to be paid</p>
                  <p className="d-flex amountf">
                    ₹
                    {formatTotalAmount(
                      appliedCoupons.length > 0 && paymentType === "Complete"
                        ? Number(finalPrice)
                        : paymentType === "Complete"
                        ? Number(afterCouponPrice) || 0
                        : (Number(
                            checkoutData?.applyTourbatchId[0]?.bookPrice
                          ) || 0) * Number(responseValue)
                    )}
                  </p>
                </div>
              </div>

              {domestincorInternational === "international" ? (
                <div className="col-md-4 lable col-lg-6 mt-3 mb-3">
                  <input
                    type="text"
                    className="form-control place2"
                    id="inputEmail4"
                    placeholder="Pan Number"
                    value={panNumber}
                    maxLength={10}
                    onChange={handleChange}
                    onBlur={validatePanCard}
                  />
                </div>
              ) : null}
              <div className="d-flex">
                <div className="form-check mt-1">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    onChange={handleCheckboxChange}
                    checked={termsAgreed}
                  />
                  <label className="form-check-label d-flex align-items-center">
                    <p className="text_pink mb-0 mx-1">
                      I have read and agreed to all
                    </p>
                    <p className="mb-0 mx-2 termandc ">
                      <a
                        href="#"
                        id="exampleModalToggleLabel"
                        onClick={toggleModal}
                      >
                        Terms and conditions
                      </a>
                    </p>
                  </label>
                </div>
              </div>
              <div className="m-4 d-flex justify-content-center align-items-center">
                <button className=" payment_btn" onClick={makePayment}>
                  Make Payment
                </button>
              </div>
              <div className="border p-4 notes_txt">
                <div
                  className="fw-semibold"
                  dangerouslySetInnerHTML={{ __html: OrderNote }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        {showModal && (
          <div
            className="modal fade show"
            id="exampleModalToggle"
            aria-hidden="true"
            aria-labelledby="exampleModalToggleLabel"
            tabIndex={1}
            style={{ display: "block", backgroundColor: "rgba(0, 0, 0, 0.5)" }}
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalToggleLabel">
                    Terms And Condition
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    onClick={toggleModal}
                    aria-label="Close"
                  ></button>
                </div>
                <div>
                  <ul>
                    <li>
                      All our bookings are on the basis of First come First
                      policy.
                    </li>
                    <li>
                      Bookings are non-transferable, non-adjustable or cannot be
                      rescheduled.
                    </li>
                    <li>
                      Trips can be rescheduled if informed 45 days prior the
                      trip (Subject to availability of preferred dates )
                    </li>
                    <li>
                      In case of cancellation of bookings from the consumer's
                      end, no booking amount shall be refunded or adjusted in
                      any case.
                    </li>
                    <li>
                      The photos/videos content created on glabol's trip (by
                      glabol's content creators or clients) is the property of
                      glabol & can also be used by glabol for advertising across
                      media platforms.
                    </li>
                    <li>
                      The IDs shall all be verified before boarding. No boarding
                      shall be entertained without a valid Govt. ID.
                    </li>
                    <li>
                      The Transfer of the bookings is not permitted. Only the
                      names mentioned at the time of confirmation shall be
                      allowed to travel.
                    </li>
                    <li>
                      Glabol reserve the rights to rearrange the itinerary/
                      transportations due to any kind of unavoidable events (i.e
                      weather condition, political condition, local union fights
                      or any others).
                    </li>
                    <li>
                      Glabol reserve the rights to rearrange the itinerary/
                      transportations due to any kind of unavoidable events (i.e
                      weather condition, political condition, local union fights
                      or any others).
                    </li>
                    <li>
                      An original individual identification proof(Aadhar card,
                      Driving License, Voter ID, Passport must be carried by the
                      traveler during the journey.
                    </li>
                    <li>
                      Glabol will not be responsible for any man made or natural
                      calamity. All the sports are safe and approved by the
                      state ministry, activities performed will be under the
                      guidance of highly trained professionals. However, in case
                      of any injury or accident during the adventure sport and
                      travel Glabol will not be liable.
                    </li>
                    <li>
                      In case of any updates in the booking, the consumer has to
                      give a prior notice to Glabol.
                    </li>
                    <li>
                      In case of inappropriate weather or any change in local
                      government norms, activities mentioned above can be
                      scheduled or canceled without any prior notice.
                    </li>
                    <li>
                      Any mischie for inappropriate attitude will not be
                      tolerated and Glabol reserves all rights to cancel such
                      bookings without any refund at any time in between the
                      trip.
                    </li>
                    <li>
                      Glabol can cancel the trip or individual booking without
                      any prior notice.
                    </li>
                    All Rights Reserved by Glabol India.
                  </ul>

                  <div className="modal-footer border-0">
                    <button
                      className="btn btn-color m-auto mb-2 "
                      style={{
                        padding: "7px 37px",
                        fontSize: "20px",
                        borderRadius: "10px",
                        background: "#ffA923",
                        margin: "4px 2px",
                        color: "#FFF",
                      }}
                      onClick={toggleModal}
                    >
                      OK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Checkout;
